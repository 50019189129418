import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/hive/projects/",
    quote_endpoint: "/hive/quotes/",
    key: "project",
    fetchKey: "projects",
    statusList: [
      {
        name: "pending",
        id: "pending",
        text: i18n.t("pages.hive.projects.statusList.pending"),
        label: i18n.t("pages.hive.projects.statusList.pending"),
        color: "warning",
      },
      {
        name: "active",
        id: "active",
        text: i18n.t("pages.hive.projects.statusList.active"),
        label: i18n.t("pages.hive.projects.statusList.active"),
        color: "primary",
      },
      {
        name: "completed",
        id: "completed",
        text: i18n.t("pages.hive.projects.statusList.completed"),
        label: i18n.t("pages.hive.projects.statusList.completed"),
        color: "success",
      },
      {
        name: "accepted",
        id: "accepted",
        text: i18n.t("pages.hive.projects.statusList.accepted"),
        label: i18n.t("pages.hive.projects.statusList.accepted"),
        color: "success",
      },
    ],
    projectTypeList: [
      {
        name: "production",
        id: "production",
        text: i18n.t("pages.hive.projects.projectTypeList.production"),
        label: i18n.t("pages.hive.projects.projectTypeList.production"),
      },
      {
        name: "quote",
        id: "quote",
        text: i18n.t("pages.hive.projects.projectTypeList.quote"),
        label: i18n.t("pages.hive.projects.projectTypeList.quote"),
      },
    ],
    shippingTypeList: [
      {
        name: "pickup",
        id: "pickup",
        text: i18n.t("pages.hive.projects.shippingTypeList.pickup"),
        label: i18n.t("pages.hive.projects.shippingTypeList.pickup"),
      },
      {
        name: "delivery",
        id: "delivery",
        text: i18n.t("pages.hive.projects.shippingTypeList.delivery"),
        label: i18n.t("pages.hive.projects.shippingTypeList.delivery"),
      },
      {
        name: "package_shipment",
        id: "package_shipment",
        text: i18n.t("pages.hive.projects.shippingTypeList.package_shipment"),
        label: i18n.t("pages.hive.projects.shippingTypeList.package_shipment"),
      },
      {
        name: "ltl",
        id: "ltl",
        text: i18n.t("pages.hive.projects.shippingTypeList.ltl"),
        label: i18n.t("pages.hive.projects.shippingTypeList.ltl"),
      },
      {
        name: "installation",
        id: "installation",
        text: i18n.t("pages.hive.projects.shippingTypeList.installation"),
        label: i18n.t("pages.hive.projects.shippingTypeList.installation"),
      },
      {
        name: "give_to_pm",
        id: "give_to_pm",
        text: i18n.t("pages.hive.projects.shippingTypeList.give_to_pm"),
        label: i18n.t("pages.hive.projects.shippingTypeList.give_to_pm"),
      },
    ],
    shippingServiceList: [
      { name: "carrier", id: "carrier", text: i18n.t("pages.hive.projects.shippingServiceList.carrier"), label: i18n.t("pages.hive.projects.shippingServiceList.carrier") },
      { name: "regular", id: "regular", text: i18n.t("pages.hive.projects.shippingServiceList.regular"), label: i18n.t("pages.hive.projects.shippingServiceList.regular") },
      { name: "express", id: "express", text: i18n.t("pages.hive.projects.shippingServiceList.express"), label: i18n.t("pages.hive.projects.shippingServiceList.express") },
    ],
    shipmentByList: [
      { name: "freightcom", text: i18n.t("pages.hive.projects.shipmentByList.freightcom") },
      { name: "customer_account", text: i18n.t("pages.hive.projects.shipmentByList.customer_account") },
    ],
    addressableTypeList: [
      { name: "billing", text: i18n.t("pages.hive.projects.addressableTypeList.billing") },
      { name: "shipping", text: i18n.t("pages.hive.projects.addressableTypeList.shipping") },
    ],
    filesApprovalList: [
      {
        name: "by_account_manager",
        text: i18n.t("pages.hive.projects.filesApprovalList.by_account_manager"),
      },
      // {
      //   name: "by_customer",
      //   text: i18n.t("pages.hive.projects.filesApprovalList.by_customer"),
      // },
      {
        name: "none",
        text: i18n.t("pages.hive.projects.filesApprovalList.none"),
      },
    ],
    permissionKey: "",
  },
  mutations: {},
  actions: {},
  getters: {
    statusList: (state) => state.statusList,
    projectTypeList: (state) => state.projectTypeList,
    shippingTypeList: (state) => state.shippingTypeList,
    shippingServiceList: (state) => state.shippingServiceList,
    shipmentByList: (state) => state.shipmentByList,
    addressableTypeList: (state) => state.addressableTypeList,
    filesApprovalList: (state) => state.filesApprovalList,
  },
};
