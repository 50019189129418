<template>
  <!-- Title -->
  <b-form-group :label="measurementTitle" v-bind="$attrs">
    <b-input-group :class="{'is-invalid-custom': error}" style="padding-left: 1px">
      <template v-slot:prepend> </template>
      <b-input
        v-bind="$attrs"
        :disabled="disabled || (isMeasurementArea && enableCalculateArea)"
        autocomplete="off"
        size="sm"
        type="text"
        @input="updateUnit"
        v-model="measurement.m"
      ></b-input>
      <template v-slot:append>
        <v-select
          :disabled="disabled"
          transition="none"
          class="sm"
          :options="fetchUnitMeasurements"
          @input="calculateConversation"
          v-model="measurement.um"
        />
      </template>
    </b-input-group>
    <small class="mt-1" v-if="!disabled && activeUnit !== measurement.um && measurement.m">
      <span>{{ `${initialValue} ${activeUnit}` }}</span>
      <i class="fa fa-arrow-right mx-1 text-primary"></i>
      <span>{{ `${measurement.m} ${measurement.um}` }}</span>
    </small>
    <div class="invalid-feedback-custom" v-if="error">{{ $t("form-errors.required") }}</div>
  </b-form-group>
</template>

<script>
import { mapGetters } from "vuex";
import { isNumber } from "lodash";
export default {
  data() {
    return {
      measurement: {
        m: null, // height // m_height
        um: null // cm // m_height_um
      },
      initialValue: 0,
      isLoad: false,
      activeUnit: null
    };
  },
  props: {
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      required: false,
      type: Object
    },
    measurement_info: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    enableCalculateArea: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    updateUnit(event) {
      this.initialValue = event;
      this.activeUnit = this.measurement.um;
    },
    calculateConversation() {
      const activeUnit = this.measurement.um;

      if (this.activeUnit === activeUnit) {
        this.measurement.m = this.initialValue;
      }
      if (
        this.conversationTable?.hasOwnProperty(`${this.activeUnit}_${activeUnit}`) &&
        (this.initialValue?.length > 0 || isNumber(this.initialValue))
      ) {
        const rate = this.conversationTable[`${this.activeUnit}_${activeUnit}`];
        this.measurement.m = rate * this.initialValue || this.measurement.m;
      }
    },
    updateValue() {
      if (this.value) {
        this.measurement.m = this.value["m_" + this.measurement_info.m];

        // If doesn't come unit measurement get Default Value Setting from Logged in Client of User
        if (!this.value["m_" + this.measurement_info.m + "_um"]) {
          // Setting Default Value
          let unit_measurement_index = this.activeUser.client.default_measurement_units.findIndex(um => {
            return um.type_name == this.measurement_info.m;
          });

          if (unit_measurement_index > -1) {
            let unit_measurement = this.activeUser.client.default_measurement_units[unit_measurement_index].code;

            this.measurement.um = unit_measurement;
          } else {
            let unit_measurement_index = this._measurementList.findIndex(um => {
              return um.name == this.measurement_info.m;
            });

            if (unit_measurement_index > -1) {
              let unit_measurement = this._measurementList[unit_measurement_index].default_unit;

              this.measurement.um = unit_measurement;
            }
          }
        } else {
          this.measurement.um = this.value["m_" + this.measurement_info.m + "_um"];
        }

        this.isLoad = true;
      }
    }
  },
  computed: {
    measurementTitle() {
      return this.$t(`general.measurements.${this.measurement_info.m}`) || "";
    },
    activeMeasurement() {
      return this._measurementList?.find(m => m.name === this.measurement_info?.m);
    },
    defaultUnit() {
      return this.activeMeasurement?.default_unit;
    },
    fetchUnitMeasurements() {
      let unit_measurement_index = this._measurementList.findIndex(um => {
        return um.name == this.measurement_info.m;
      });
      if (unit_measurement_index > -1) {
        return this._measurementList[unit_measurement_index].units;
      }
    },
    isMeasurementArea() {
      return this?.measurement_info?.m === "area";
    },
    ...mapGetters({
      activeUser: "activeUser",
      _measurementList: "inventory_product_types/measurement_list",
      conversationTable: "inventory_product_types/conversationTable"
    })
  },
  watch: {
    measurement: {
      handler(val) {
        if (this.isLoad) {
          let measurement_unit_value = {};
          measurement_unit_value["m_" + this.measurement_info.m] = this.measurement.m;
          measurement_unit_value["m_" + this.measurement_info.m + "_um"] = this.measurement.um;
          this.$emit("input", measurement_unit_value);
        }
      },
      deep: true
    },
    value: {
      deep: true,
      handler(val) {
        this.updateValue();
      }
    }
  },
  created() {
    this.updateValue();
  },
  mounted() {
    this.activeUnit = this.defaultUnit;
    this.updateUnit(this.measurement.m);
  }
};
</script>

<style></style>
