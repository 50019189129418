import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/hive/work_orders/",
    key: "work_order",
    fetchKey: "work_orders",
    statusList: [
      {
        name: "pending",
        id: "pending",
        text: i18n.t("pages.hive.work_orders.statusList.pending"),
        label: i18n.t("pages.hive.work_orders.statusList.pending"),
        color: "warning"
      },
      {
        name: "completed",
        id: "completed",
        text: i18n.t("pages.hive.work_orders.statusList.completed"),
        label: i18n.t("pages.hive.work_orders.statusList.completed"),
        color: "success",
        permission_key: "wo_complete"
      },
      {
        name: "pending_approval",
        id: "pending_approval",
        text: i18n.t("pages.hive.work_orders.statusList.pending_approval"),
        label: i18n.t("pages.hive.work_orders.statusList.pending_approval"),
        color: "outline-danger"
      },
      {
        name: "at_production",
        id: "at_production",
        text: i18n.t("pages.hive.work_orders.statusList.at_production"),
        label: i18n.t("pages.hive.work_orders.statusList.at_production"),
        color: "secondary"
      },
      {
        name: "on_hold",
        id: "on_hold",
        text: i18n.t("pages.hive.work_orders.statusList.on_hold"),
        label: i18n.t("pages.hive.work_orders.statusList.on_hold"),
        color: "dark",
        permission_key: "wo_put_on_hold"
      },
      {
        name: "declined",
        id: "declined",
        text: i18n.t("pages.hive.work_orders.statusList.declined"),
        label: i18n.t("pages.hive.work_orders.statusList.declined"),
        color: "danger",
        permission_key: "wo_decline"
      },
      {
        name: "cancelled",
        id: "cancelled",
        text: i18n.t("pages.hive.work_orders.statusList.cancelled"),
        label: i18n.t("pages.hive.work_orders.statusList.cancelled"),
        color: "cancel"
      }
    ],
    archiveTypeList: [
      {
        name: "none",
        text: i18n.t("pages.hive.work_orders.archiveTypeList.none")
      },
      {
        name: "all",
        text: i18n.t("pages.hive.work_orders.archiveTypeList.all")
      },
      {
        name: "only_graphic",
        text: i18n.t("pages.hive.work_orders.archiveTypeList.only_graphic")
      },
      {
        name: "only_courbes",
        text: i18n.t("pages.hive.work_orders.archiveTypeList.only_courbes")
      },
      // {
      //   name: "only_graphic_as_reference",
      //   text: i18n.t("pages.hive.work_orders.archiveTypeList.only_graphic_as_reference")
      // }
      {
        name: "only_original_files",
        text: i18n.t("pages.hive.work_orders.archiveTypeList.archive_file_to_modify")
      }
    ],
    invoicedStatus: [
      {
        name: "pending",
        text: i18n.t("pages.hive.work_orders.invoicedStatus.pending"),
        color: "warning"
      },
      {
        name: "completed",
        text: i18n.t("pages.hive.work_orders.invoicedStatus.completed"),
        color: "success"
      },
      {
        name: "partial",
        text: i18n.t("pages.hive.work_orders.invoicedStatus.partial"),
        color: "outline-success"
      }
    ],
    completeStepSlugList: [
        {
          "id": "step_graphic_1_up",
          "label": "Infographie - 1up"
        },
        {
          "id": "step_pdf_approval_by_project_manager",
          "label": "Approbation PDF - Chargé de projets"
        },
        {
          "id": "step_pdf_approval_by_customer",
          "label": "Approbation PDF - Client"
        },
        {
          "id": "step_graphic_print_layout",
          "label": "Infographie -layout"
        },
        {
          "id": "step_printing_colarado_hp",
          "label": "Impression - Colorado / HP / Epson"
        },
        {
          "id": "step_printing_teleios",
          "label": "Impression - Teleios"
        },
        {
          "id": "step_vutek_roll",
          "label": "Impression - Vutek roll to roll"
        },
        {
          "id": "step_printing_vuetek_flatbed",
          "label": "Impression - Vutek Flatbed"
        },
        {
          "id": "step_swiss_qprint_nyala",
          "label": "Impression - SwissQprint Nyala"
        },
        {
          "id": "step_decoupe_cnc",
          "label": "Découpe Kongsberg"
        },
        {
          "id": "step_decoupe_icut",
          "label": "Découpe Zund"
        },
        {
          "id": "step_decoupe_laser_tissu",
          "label": "Découpe laser tissu"
        },
        {
          "id": "step_decoupe_cnc_protek",
          "label": "Découpe CNC protek"
        },
        {
          "id": "step_decoupe_vinyle",
          "label": "Découpe de vinyle"
        },
        {
          "id": "step_peinture",
          "label": "Peinture"
        },
        {
          "id": "step_laminage",
          "label": "Laminage"
        },
        {
          "id": "step_montage",
          "label": "Montage"
        },
        {
          "id": "step_emballage",
          "label": "Emballage"
        },
        {
          "id": "step_etirage_faux_cadre",
          "label": "Finition - étirage faux cadre"
        },
        {
          "id": "step_couture",
          "label": "Couture"
        },
        {
          "id": "step_travaux_exterieurs",
          "label": "Travaux extérieurs"
        },
        {
          "id": "step_structures",
          "label": "Structures"
        },
        {
          "id": "step_quincaillerie",
          "label": "Quincaillerie Signaletique"
        },
        {
          "id": "step_quincaillerie_code_s",
          "label": "Quincaillerie Code S"
        },
        {
          "id": "step_quincaillerie_couture",
          "label": "Quincaillerie Couture"
        },
        {
          "id": "step_lamcom_inventory",
          "label": "Produits inventaires Lamcom"
        },
        {
          "id": "step_finition_signalitique",
          "label": "Finition / Signalétique"
        },
        {
          "id": "step_facturation",
          "label": "Facturation"
        },
        {
          "id": "step_credit_card_payment",
          "label": "Paiement carte de crédit"
        },
        {
          "id": "step_livraison",
          "label": "Livraison"
        },
        {
          "id": "step_contacter_client",
          "label": "Contacter client"
        },
        {
          "id": "step_pickup",
          "label": "Pickup"
        },
        {
          "id": "step_remettre_au_charge_de_projet",
          "label": "Remettre au chargé de projet"
        },
        {
          "id": "step_installation",
          "label": "Installation"
        }
      ],
    permissionKey: ""
  },
  mutations: {},
  actions: {},
  getters: {
    statusList: (state) => state.statusList,
    archiveTypeList: (state) => state.archiveTypeList,
    invoicedStatus: (state) => state.invoicedStatus,
    completeStepSlugList: (state) => state.completeStepSlugList
  }
};
